const env = process.env.REACT_APP_ENVIRONMENT ?? "production";

const baseConfig: BaseConfigType = {
    env: "default",
    obRole: "admin",
    host: "https://api.dotsco.co",
    S3: "https://dotsco.s3.me-south-1.amazonaws.com/",
    pdfLink: () => getAPIV2URL("getPdfLink"),
    profile: () => getAPIV2URL("profile"),
    userContactAsVcf: () => getAPIURL("getUserContactAsVcf"),
    debug: false,
    bannerConfig: {
        android: "https://play.google.com/store/apps/details?id=com.infinitech.dots",
        ios: "https://apps.apple.com/in/app/dots-tap-my-dots/id1599017164",
        windows: "https://dotsco.co/#/",
        kindle: "https://dotsco.co/#/",
    },
};

const production: BaseConfigType = {
    ...baseConfig,
    ...{
        env: "production",
    },
};

const staging: BaseConfigType = {
    ...baseConfig,
    ...{
        env: "staging",
        host: "https://stage.api.dotsco.co",
        debug: true,
    },
};

const development: BaseConfigType = {
    ...baseConfig,
    ...{
        env: "development",
        host: "http://localhost:3000",
        debug: true,
    },
};

const config: ConfigType = {
    development,
    staging,
    production,
};

export default config[env];

export const API_ROOT = `${config[env].host}/public`;

export const getAPIURL = (resourceURL: string): string => `${API_ROOT}/${resourceURL}`;

export const API_ROOT_V2 = `${config[env].host}/api/public`;

export const getAPIV2URL = (resourceURL: string): string => `${API_ROOT_V2}/${resourceURL}`;

if(config[env].debug) {
    console.debug(`Environment: (${env})`);
    console.debug(`Config: ${JSON.stringify(config[env])}`);
}
