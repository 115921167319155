import { lazy } from "react";
import { Navigate, Route, RouterProvider, createHashRouter, createRoutesFromElements } from "react-router-dom";
import App from "./app/App";
import { createRoot } from "react-dom/client";
import "./i18n";

import "./App.scss";

import "react-toastify/dist/ReactToastify.css";
import "primereact/resources/themes/lara-dark-blue/theme.css";
import "primereact/resources/primereact.min.css";
import { Spinner } from "react-bootstrap";

const Error404 = lazy(() => import("./app/error-pages/Error404"));
const router = createHashRouter(
    createRoutesFromElements(
        <Route Component={App}>
            <Route path="/:username/:shortkey/:profileType/:redirectkey" lazy={() => import("./app/user")} />
            <Route path="/404" Component={Error404} />
            <Route path="*" element={<Navigate to="/404" />} />
        </Route>
    )
);

const root = createRoot(document.getElementById("root")!);
root.render(
    <RouterProvider
        router={router}
        fallbackElement={
            <div className="container-fluid p-0 h-100 d-flex flex-column justify-content-center align-items-center fw-normal">
                <Spinner animation="border" variant="primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        }
    />
);
