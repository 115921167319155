// import React, { useState } from "react";
// import request from "./shared/request";

import { FC, ReactNode, createContext } from "react";

export const AppContext = createContext({});

const AppProvider: FC<{ children: ReactNode }> = props => {
    // const [users, setUsers] = useState([]);
    // const [uuidList, setUuidList] = useState([]);
    // const [zipcodes, setZipCodes] = useState([]);
    // const [products, setProducts] = useState([]);
    // const [orders, setOrders] = useState([]);
    // const [settings] = useState({});

    // const getAllUsers = async () => {
    //     const response = await request("/admin/registeredUserList");
    //     if (response && response.data) {
    //         setUsers(response.data);
    //     }
    // };

    // const getAllUuid = async (pageno?: String | number) => {
    //     const response = await request(pageno ? `/public/getUuidList?page=${pageno}` : "/public/getUuidList");
    //     if (response && response.data) {
    //         setUuidList(response.data);
    //     }
    // };

    // const getAllZipcodes = async (pageno?: String | number) => {
    //     const response = await request(pageno ? `/admin/getZipCodeDetails?page=${pageno}` : "/admin/getZipCodeDetails");
    //     if (response && response.data) {
    //         setZipCodes(response.data);
    //     }
    // };

    // const getAllProducts = async (pageno?: String | number) => {
    //     const response = await request(
    //         pageno ? `/admin/getProductTypeList?page=${pageno}` : "/admin/getProductTypeList"
    //     );
    //     if (response && response.data) {
    //         setProducts(response.data);
    //     }
    // };

    // const getAllOrders = async (pageno?: String | number) => {
    //     const response = await request(pageno ? `/admin/viewAllOrders?page=${pageno}` : "/admin/viewAllOrders");
    //     if (response && response.data) {
    //         setOrders(response.data);
    //     }
    // };

    return (
        <AppContext.Provider
            value={{
                
            }}
        >
            {props.children}
        </AppContext.Provider>
    );
};

const AppConsumer = AppContext.Consumer;

export { AppProvider, AppConsumer };
