// import { TransifexI18next } from "@transifex/i18next";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from "i18next-http-backend";
import config from "./api/config";

// const txBackend = new TransifexI18next({
//     token: "",
// });

const fallbackLng = "tr";
const availableLanguages = ["en", "tr"];

i18n.use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init<{}>({
        load: "languageOnly",
        fallbackLng,
        debug: config.debug,
        cleanCode: true,
        preload: false,
        supportedLngs: availableLanguages,
        nonExplicitSupportedLngs: true,
        updateMissing: true,
        saveMissing: true,
        detection: {
            order: ["localStorage", "navigator", "htmlTag", "path", "subdomain"],
            lookupQuerystring: "lng",
            lookupCookie: "i18next",
            lookupLocalStorage: "i18nextLng",
            lookupFromPathIndex: 0,
            lookupFromSubdomainIndex: 0,
        },
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;