import { Suspense, FC } from "react";
import "../assets/css/reset.css";
import "../assets/css/font-awesome.css";
import "../assets/css/styles.css";

import { ToastContainer } from "react-toastify";
import { AppConsumer, AppProvider } from "./app-context";
import { Spinner } from "react-bootstrap";
import { Outlet } from "react-router-dom";

const App: FC = () => {
    return (
        <AppProvider>
            <AppConsumer>
                {() => (
                    <Suspense
                        fallback={
                            <div className="container-fluid p-0 h-100 d-flex flex-column justify-content-center align-items-center fw-normal">
                                <Spinner animation="border" variant="primary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>
                        }
                    >
                        <div className="container-fluid p-0 vh-100">
                            <Outlet />
                        </div>
                        <ToastContainer
                            position="top-center"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="light"
                        />
                    </Suspense>
                )}
            </AppConsumer>
        </AppProvider>
    );
};

export default App;
